import FeaturesCard from '../../../components/FeaturesCard/FeaturesCard'
import FeaturesHero from '../../../components/FeaturesHero/FeaturesHero'
import styles from './Tutoring.module.css'
import tutoringHeadingImg from './tutoringHeading.svg'
import sops from './sops1.png'
import map from './map.svg'
import notes from './notes.svg'
import gci from './gci.svg'
import notesF from './notes_f.svg'
import noteTypes from './privPubNotes.svg'
import FeatureCardSmall from '../../../components/FeaturesCardSmall/FeaturesCardSmall'
import Meteorites from '../../../components/metorites/meteorites'
import { useEffect, useState } from 'react'
import useScrollAnimation from '../../../Hooks/useScrollAnimation'
import PlayCircleOutlineRoundedIcon from '@mui/icons-material/PlayCircleOutlineRounded';
import VideoPopUp from '../../../components/videoPopUp/videoPopUp'
import ContactFrom from '../../../components/ContactForm/ContactFrom'
import FeaturesHeroSection from '../../../components/FeaturesHeroSection/FeaturesHeroSection'
import { ToastContainer } from 'react-toastify'

const videos = [
    <iframe src="https://www.youtube.com/embed/ihHd6y0DRzQ?si=FT0GMLvGy2aQYWFR" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>,
    <iframe src="https://www.youtube.com/embed/h8Acu0_FB8o?si=RIifzMgyIwZuWbUn" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>,
    <iframe src="https://www.youtube.com/embed/LC5wftG3lTs?si=iiznrQ5_gDJb2sPD" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
]

export default function Tutoring(props) {

    const watchDemoButtonText = <div className='d-flex justify-content-between gap-2 align-items-center'><PlayCircleOutlineRoundedIcon />Watch Demo</div>
    const [vidTrigger, setVidTrigger] = useState(false)
    const [vidIdx, setVidIdx] = useState(0)

    const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    useScrollAnimation(styles.fCard, styles.popIn, 0.5)

    return (
        <div className={`container-fluid h-100 ${styles.container} position-relative px-0`}>
             <ToastContainer/>
            <VideoPopUp element={videos[vidIdx]} trigger={vidTrigger} setTrigger={setVidTrigger} />
            <div className={`row`}>
                <div className='col-12'>
                    <FeaturesHeroSection image={tutoringHeadingImg} desc='Evallo offers a seamless and intuitive experience to help you organize, track, and optimize your tutoring sessions. Go ahead and explore!' title='Calendar & Scheduling' buttonText='Get Started' buttonUrl="https://app.evallo.org/signup" page ="calendar" />
                </div>
            </div>
            <div className='row mb-3'>
                <div className={`col-12  ${styles.fCard} `}>
                    <FeaturesCard rev={true} img={sops} heading="Organized Sessions" description="Need to match tutors and students based on their availability? Want to have a clean view of your week, month or even the year? We got you." buttonText={watchDemoButtonText} idx={0} setVidIdx={setVidIdx} trigger={vidTrigger} setTrigger={setVidTrigger} />
                </div>
                <div className={`col-12  ${styles.fCard} `}>
                    <FeaturesCard rev={false} img={map} heading="Universal Time-zones" description='Whether you tutor locally or globally, Evallo is useful in keeping track of when you gotta do what you gotta do. Set your calendar according to your preferred time zone!' buttonText={watchDemoButtonText} idx={1} setVidIdx={setVidIdx} trigger={vidTrigger} setTrigger={setVidTrigger} />
                </div>
                <div className={`col-12  ${styles.fCard} `}>
                    <FeaturesCard rev={true} img={notes} heading="Session Notes & Tags" description='Use Client Notes to share updates with your parents and students, while Internal Notes will help you keep a track of progress made in each session. Don’t have the time? Use customizable Session Tags to make it quick!' buttonText={watchDemoButtonText} idx={2} setVidIdx={setVidIdx} trigger={vidTrigger} setTrigger={setVidTrigger} />
                </div>
            </div>
            <div className={`row row-gap-3 ${styles.smallCard}`}>
                <div className={`col-12 col-lg-4 ${styles.fCard} `}>
                    <FeatureCardSmall heading="Integrations" description='We are soon adding 3rd party integrations like Google Calendar, Outlook, G-Meet, Zoom, Zapier, and more!' img={gci} />
                </div>
                <div className={`col-12 col-lg-4 ${styles.fCard} `}>
                    <FeatureCardSmall heading="Session Tracking" description="Locate all the notes ever recorded for a student under their profile." img={notesF} />
                </div>
                <div className={`col-12 col-lg-4 ${styles.fCard} `}>
                    <FeatureCardSmall heading='Segregated Views' description='Parents, Students, Tutors and Admins get their own preferred calendar views.' img={noteTypes} />
                </div>
            </div>
            <div className='row p-0 m-0 mt-4'>
                <div className='col-12 p-0 m-0'>
                <ContactFrom  {...{
                firstName,
                lastName,
                email,
                phone,
                setFirstName,
                setLastName,
                setPhone,
                message,
                setMessage,
                setEmail,
                queryFor:"sales",
              }} />
                </div>
            </div>
            <Meteorites />
        </div>
    )

}