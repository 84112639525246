import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";

import "./navbar.css";
import navLogo from "../../assets/logo/evallo-nav-logo.svg";
import downArrow from "../../assets/icons/down.svg";
import navPlayBtn from "../../assets/icons/play-circle.svg";
import navTabImage from "../../assets/icons/Nav-tab-Image.svg";
import navRightArrow from "../../assets/icons/chevron-right.svg";
import navX from "../../assets/icons/x.svg";
import navOpen from "../../assets/icons/menu-line.svg";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LeadForm from "../LeadForm/LeadForm";
import PopUp from "../PopUp/PopUp";
import BookIcon from "../../assets/icons/BookIcon";
import Grid from "../../assets/icons/Grid";
import CalendarIcon from "../../assets/icons/CalendarIcon";
import InvoiceIcon from "../../assets/icons/InvoiceIcon";

const Navbar = () => {
  const [openMenu, setOpenMenu] = useState(false);
  const [menu, setMenu] = useState(true);
  const [hovered, setHovered] = useState(false);
  const [changeArrowColor, setChangeArrowColor] = useState(false);
  const [isMouseInNav, setIsMouseInNav] = useState(false);
  const [leadFormTrigger, setLeadFormTrigger] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  useState(() => {
    window.addEventListener("resize", () => {
      setHovered(false);
    });

    return () => {
      window.removeEventListener("resize", () => { });
    };
  }, []);

  return (
    <header>
      {leadFormTrigger ? (
        <PopUp trigger={leadFormTrigger} setTrigger={setLeadFormTrigger}>
          <LeadForm
            {...{
              firstName,
              lastName,
              email,
              phone,
              setFirstName,
              setLastName,
              setPhone,
              message,
              setMessage,
              setEmail,
              setFormPopUpTrigger: setLeadFormTrigger,
            }}
          />
        </PopUp>
      ) : (
        <></>
      )}
      <div className="navigation">
        <Link to="/">
          <div>
            <img className="navigation-logo" src={navLogo} alt="" />
          </div>
        </Link>
        <div
          className={`middle-navigation-menu ${menu ? "show-navmenu" : "hide-navmenu"
            }`}
          style={{ display: openMenu && menu && "block" }}
        >
          <div className={`${menu ? "show-navmenu" : ""}`}>
            <div className="dropDown-menu-parent">
              {/* <!-- <span>Features</span> --> */}
              <div className="dropDown-menu navigation-features">
                <div className="d-flex align-items-center gap-2 dropDown-menu navigation-features justify-content-between w-100 justify-content-lg-start">
                  <NavLink
                    to="features"
                    className={({ isActive }) => {
                      if (isActive) {
                        setChangeArrowColor(isActive);
                        return "navSelected";
                      } else {
                        setChangeArrowColor(isActive);
                        return "navNotSelected";
                      }
                    }}
                    onClick={() => setMenu(false)}
                  >
                    Features
                  </NavLink>
                  <button
                    className="btn p-0"
                    onClick={(e) => setHovered(!hovered)}
                    onMouseEnter={(e) => setHovered(true)}
                  >
                    <ExpandMoreIcon
                      className={
                        changeArrowColor ? "navSelected" : "navNotSelected"
                      }
                      style={{ pointerEvents: "none" }}
                    />
                  </button>
                </div>

                <div
                  className={`features-menu ${hovered ? "d-block show-features" : "d-none"} `}
                  onMouseLeave={(e) => {
                    setHovered(false);
                    setIsMouseInNav(false);
                  }}
                  onMouseEnter={(e) => {
                    setIsMouseInNav(true);
                    setHovered(true);
                  }}
                >
                  <div className="features-menu-tab">
                    <div className="features-tab-left">
                      <div>
                        <Link
                          to="features/dsat"
                          onClick={() => {
                            setMenu(false);
                            setHovered(false);
                          }}
                        >
                          <div className="features-tab-items">
                            <div>
                              {/* <img src={navBook} alt="" /> */}
                              <BookIcon />
                            </div>
                            <div>Assignments & Grading</div>
                          </div>
                        </Link>
                      </div>
                      <div>
                        <Link
                          to="features/crm"
                          onClick={() => {
                            setMenu(false);
                            setHovered(false);
                          }}
                        >
                          <div className="features-tab-items">
                            <div>
                              <Grid />
                            </div>
                            <div>
                              <div>CRM</div>
                            </div>
                          </div>
                        </Link>
                      </div>
                      <div>
                        <Link
                          to="features/calendar"
                          onClick={() => {
                            setMenu(false);
                            setHovered(false);
                          }}
                        >
                          <div className="features-tab-items">
                            <div>
                              <CalendarIcon />
                            </div>
                            <div>
                              <div>Calendar & Scheduling</div>
                            </div>
                          </div>
                        </Link>
                      </div>
                      <div>
                        <Link
                          to="features/invoicing"
                          onClick={() => {
                            setMenu(false);
                            setHovered(false);
                          }}
                        >
                          <div className="features-tab-items">
                            <div>
                              <InvoiceIcon />
                            </div>
                            <div>
                              <div>Invoicing</div>
                            </div>
                          </div>
                        </Link>
                      </div>
                      {/* <div>
                                                <Link onClick={() => setMenu(false)}>
                                                    <div className="features-tab-items">
                                                        <div>
                                                            <img src={navPlayBtn} alt="" />
                                                        </div>
                                                        <div>
                                                            <div>
                                                                <p>Additional Feature <span
                                                                    className="new-features-lable">New</span></p>
                                                            </div>
                                                            <div className="features-tab-items-slogan">New features
                                                                description
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div> */}
                    </div>
                    <div className="features-tab-right">
                      <div>
                        <div>
                          <img src={navTabImage} alt="" />
                        </div>
                        <div>
                          <h2>We've just released an update!</h2>
                          <p>
                            Evallo's latest update brings faster speeds and smoother navigation.
                          </p>
                        </div>
                        <Link to="https://app.evallo.org/" target="_blank">
                          <span>Check it out</span>
                          <img src={navRightArrow} alt="" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="non-dropDown-menu dropDown-menu">
              <NavLink
                to="about"
                className={({ isActive }) =>
                  isActive ? "navSelected" : "navNotSelected"
                }
                onClick={() => setMenu(false)}
              >
                About Us
              </NavLink>
            </div>
            <div className="non-dropDown-menu dropDown-menu">
              <NavLink
                to="pricing"
                className={({ isActive }) =>
                  isActive ? "navSelected" : "navNotSelected"
                }
                onClick={() => setMenu(false)}
              >
                Pricing
              </NavLink>
            </div>
            <div className="non-dropDown-menu dropDown-menu">
              <NavLink
                to="support"
                className={({ isActive }) =>
                  isActive ? "navSelected" : "navNotSelected"
                }
                onClick={() => setMenu(false)}
              >
                Support
              </NavLink>
            </div>
            <div className="non-dropDown-menu dropDown-menu">
              <NavLink
                to="contact-us"
                className={({ isActive }) =>
                  isActive ? "navSelected" : "navNotSelected"
                }
                onClick={() => setMenu(false)}
              >
                Contact us
              </NavLink>
            </div>

          </div>

          <div>
            <div className="nav-btn">

              <Link to="https://app.evallo.org/signin" target='_blank'><button className="login-btn rounded-5">Log in</button></Link>
              <button
                className="login-btn rounded-5"
                onClick={(e) => setLeadFormTrigger(true)}
              >
                Book Demo
              </button>
            </div>
          </div>
        </div>

        <div className={`menu-btn`}>
          <div
            onClick={() => {
              setOpenMenu(!openMenu);
              setMenu(true);
            }}
            className={`open-menu-btn`}
          >
            {openMenu && menu ? (
              <svg width="28" height="20" viewBox="0 0 28 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.5" y="0.5" width="27" height="19" rx="9.5" stroke="#0671E0" />
                <path d="M18 6L10 14" stroke="#0671E0" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M10 6L18 14" stroke="#0671E0" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              </svg>

            ) : (
              <div className="mbile-nav-open-btn">
                <img src={navOpen} alt="" />
                <div>Menu</div>
              </div>
            )}
          </div>
        </div>
      </div>
    </header>
  );
};

export default Navbar;