import { Outlet } from 'react-router-dom'
import './Stage.css'
import Navbar from '../../components/Navbar/Navbar'
import Footer from '../../components/Footer/Footer'
import { useEffect } from 'react'
import { ToastContainer } from 'react-toastify'



const Stage = () =>{

    useEffect(()=>{
    },[])

    return (
        <div className='stage-container'>
            <div className='navbar-container'>
                <Navbar /> 
            </div>
            <div className='content-container'>
                <Outlet />
            </div>
            <div className='footer-container'>
                <Footer />
            </div>
        </div>
    )
}

export default Stage