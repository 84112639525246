import React from 'react'
import "./ourTeam.css"

import pkt from "../../assets/images/about-us/PKT.jpg"
import shreen from "../../assets/images/about-us/SAR.jpg"
import kartik from "../../assets/images/about-us/kartik.png"
import abhishek from "../../assets/images/about-us/Abishek.JPG"
import sanika from "../../assets/images/about-us/sanika.jpg"
import Aaliya from "../../assets/images/about-us/Aaliya A.jpeg"
import Arohi from "../../assets/images/about-us/Aarohi S.png"
import Abhijit from "../../assets/images/about-us/Abhijit N.png"
import Akshit from "../../assets/images/about-us/Akshit.jpeg"
import Anuj from "../../assets/images/about-us/Anuj k.jpeg"
import ujjwal from "../../assets/images/about-us/Ujjwal T.png"
import ZOya from "../../assets/images/about-us/Zoya K.jpeg"
import Mahidhar from "../../assets/images/about-us/Mahidhar.jpeg"
import Mushahid from "../../assets/images/about-us/Mushahid Javed.jpeg"
import MG from "../../assets/images/about-us/0I6A9969~3.jpg"


import TeamProfile from '../TeamProfile/TeamProfile'



const OurTeam = () => {

    const teams = [
        {
            "name": "Kartik Sarda",
            "position": "Founder & CEO",
            "avatar": kartik
        },
        {
            "name": "Prashant Kumar Tiwari",
            "position": "Co-Founder & CBO",
            "avatar": pkt
        },
        {
            "name": "Sanika Rajpure",
            "position": "Full-Stack Engineer",
            "avatar": sanika
        },
        {
            "name": "Abhishek Harish Dantale",
            "position": "Full-Stack Engineer",
            "avatar": abhishek
        },
        {
            "name": "Mushahid Javed",
            "position": "Full Stack Developer",
            "avatar": Mushahid
        },
        {
            "name": "Mohammad Guahar",
            "position": "Full Stack Developer",
            "avatar": MG
        },
        {
            "name": "Anuj Kumar Singh",
            "position": "Full Stack Developer Intern",
            "avatar": Anuj
        },
        {
            "name": "Mahidhar Naidu Bongu",
            "position": "QA Tester",
            "avatar": Mahidhar
        },
        {
            "name": "Abhijit Nayak",
            "position": "Product Designer",
            "avatar": Abhijit
        },
        {
            "name": "Arohee Sinha",
            "position": "Jr. Product Designer",
            "avatar": Arohi
        },
        {
            "name": "Ujjal Senapati",
            "position": "Graphic Design Intern",
            "avatar": ujjwal
        },
        {
            "name": "Akshit Kumar",
            "position": "Generalist Intern",
            "avatar": Akshit
        },
        {
            "name": "Shirin Shahana Rahman",
            "position": "Academic Content Organizer",
            "avatar": shreen
        },
        {
            "name": "Zoya Kapadia",
            "position": "Academic Content Creator",
            "avatar": ZOya
        },
        {
            "name": "Aliya Akhtar",
            "position": "HR Analyst",
            "avatar": Aaliya
        },
    ]
    return (
        <div className='our-team'>
            <div className='text-center our-team-title'>
                <h1>Meet our team</h1>
                <p>Our philosophy is simple — hire a team of diverse, passionate people and foster a culture that empowers you to do you best work.</p>
            </div>
            <div className='row w-100 p-0 row-gap-lg-5 row-gap-2 our-team-info  ' style={{display:"flex", alignItems:"center", justifyContent:"center"}} >
                {
                    teams.map((el, i) => {
                        return <TeamProfile key={i} name={el.name} position={el.position} image={el.avatar} />
                    })
                }


            </div>
        </div>
    )
}

export default OurTeam