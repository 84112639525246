import { toast } from "react-toastify";
import { insertQueryInDynamoDb } from "../../Utils/utils";
import { ButtonFilled } from "../Buttons";
import "./ContactForm.css";
import { ToastContainer } from "react-toastify";
import EnquiryBg from "../Enquiry-form/Enquiry-form-bg-vector/EnquiryBg";
import { useState } from "react";

const ContactFrom = ({
  firstName,
  setFirstName,
  lastName,
  setLastName,
  email,
  setEmail,
  phone,
  setPhone,
  message,
  setMessage,
  queryFor,
  cases,
  setFormTrigger,
}) => {
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const isEmail = (val) => {
    let regEmail =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!regEmail.test(val)) {
      return false;
    } else {
      return true;
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (
      firstName === "" ||
      email === "" ||
      lastName === "" ||
      phone === ""
    ) {
      toast.warn("Please fill required fields.");
      return;
    }
    if (phone.length > 10) {
      toast.warn("Enter correct phone number");
      return;
    }
    if (isEmail(email) == false) {
      toast.warn("Enter correct email.");
      return;
    }

    setIsButtonDisabled(true);
    try {
      const result = await insertQueryInDynamoDb({
        firstName: firstName,
        lastName: lastName,
        email: email,
        message: "No Data",
        phone: phone,
        queryFor: "sales",
      });

      if(setFormTrigger){
        setFormTrigger(false);
      }
      window.history.pushState({}, "", `book-demo/call-scheduling`);
      toast.success("Thank you for entering the query.");

      window.ApolloMeetings.submit({
        onSuccess: () => {
          toast.success(
            "Meeting booked successfully. Looking forward to meet you."
          );
          setFirstName("")
          setLastName("")
          setPhone("")
          setEmail("")
          setMessage("")
        },
      });
      // const result = await insertQueryInDynamoDb({
      //   firstName: first_name,
      //   lastName: last_name,
      //   email: email,
      //   phone: phone,
      //   message: "No Data",
      //   ...(props.queryFor && { queryFor: props.queryFor }),
      // });

      // e.target.first_name.value = "";
      // e.target.last_name.value = "";
      // e.target.email.value = "";
      // e.target.phone.value = "";
      // toast.success("Thank you for entering the query.");
      // window.history.pushState({}, "", `book-demo/call-scheduling`);
      // window.ApolloMeetings.submit({
      //   onSuccess: () => {
      //     toast.success(
      //       "Meeting booked successfully. Looking forward to meet you."
      //     );
      //   },
      // });
    } catch (err) {
      console.log(err);
      toast.error("Oops an error occured");
    }
    setIsButtonDisabled(false);
  };

  return (
    <div className={` ${
     cases == "popup" ? "form-outer_c" : "form-outer"
    } _e w-100 d-flex justify-content-center align-items-center m-0 p-0`}>
      <form
        className="position-relative z-2"
      >
        <div className={`form-container ${cases == "popup" && "form_overwrite"} `}>
          <div className="form-heading w-100  d-flex flex-column align-items-start justify-content-top text-center">
            <div className="col-12 line-2  mb-1 text-start">
              Join 160+ tutoring businesses growing with Evallo
            </div>
            <div className="col-12 line-1  text-start">
              Ready to scale your tutoring business?
            </div>
          </div>
          <div className="form-content-1 row mt-4">
            <div className="col-12 col-lg-6">
              <input
                type="text"
                onChange={(e) => setFirstName(e.target.value)}
                id="first_name"
                placeholder="First Name*"
                value={firstName}
                className=" border-0"
                name="first_name"
              />
            </div>
            <div className="col-12 col-lg-6">
              <input
                type="text"
                onChange={(e) => setLastName(e.target.value)}
                id="last_name"
                value={lastName}
                placeholder="Last Name*"
                className=" border-0"
                name="last_name"
              />
            </div>
          </div>
          <div className="form-content-2 row mt-4">
            <div className="col-12">
              <input
                type="phone"
                onChange={(e) => setPhone(e.target.value)}
                id="phone"
                value={phone}
                placeholder="Phone Number*"
                className=" border-0"
                name="phone"
              />
            </div>
          </div>
          <div className="form-content-2 row mt-4">
            <div className="col-12 ">
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email*"
                className=" border-0"
                name="email"
              />
            </div>
          </div>
          <div className="form-content-3 row mt-4 gap-3">
            <div className="col-12">
              <div className="d-flex d-none d-lg-flex justify-content-center align-items-center">
                <button
                className="contact_submit_btn"
                  style={{ fontSize: "16px", padding: "12px 32px" }}
                  disabled={isButtonDisabled}
                   onClick={(e) => handleFormSubmit(e)}
                >
                  {!isButtonDisabled ? "Schedule Free Demo" : "Sending ..."}
                </button>
              </div>
              <div className="d-flex w-100 d-lg-none justify-content-center align-items-center w-100">
                <button
                 className="contact_submit_btn"
                  style={{
                    width: "100%",
                    fontSize: "16px",
                    padding: "12px 24px",
                  }}
                  disabled={isButtonDisabled}
                  onClick={(e) => handleFormSubmit(e)}
                >
                  {!isButtonDisabled ? "Schedule Free Demo" : "Sending ..."}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="d-none d-lg-block"></div>
      </form>
    </div>
  );
};

export default ContactFrom;
