import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import apiURL from "../apiURL";
import { Link } from "react-router-dom";
import { getTokenCookie } from "../Utils/authTools";
import { remove } from "react-cookies";
import circiniusUrl from "../criciniusApiUrl";
const AWS = require("aws-sdk");

function redirectMail() {
  window.open("mailto:support@evallo.org", "_blank");
}

function redirectEvallo() {
  window.open("https://app.evallo.org", "_blank");
}

function redirectEvalloSignup() {
  window.open("https://app.evallo.org/signup", "_blank");
}

async function insertNewsLetterEmailInDynamoDb({ email }) {
  try {
    const result = await axios({
      method: "POST",
      url: apiURL + "/api/customers/newsletter",
      data: {
        email: email,
      },
    });
    return result;
  } catch (err) {
    throw err;
  }
}


async function insertQueryInDb({
  firstName,
  lastName,
  phone,
  message,
  email,
  queryFor,
}){
  try {

    const result = await axios({
      method: "POST",
      url: apiURL + "/api/customers/contact",
      data: {
        firstName: firstName,
        lastName: lastName,
        phone: phone,
        message: message,
        email: email,
        ...(queryFor && { emailFor: queryFor }),
      },
    });

    return result;
    
  } catch (err) {
    throw err;
  }
}

async function insertQueryInDynamoDb({
  firstName,
  lastName,
  phone,
  message,
  email,
  queryFor,
}) {
  try {
    const result = await axios({
      method: "POST",
      url: apiURL + "/api/customers/query",
      data: {
        firstName: firstName,
        lastName: lastName,
        phone: phone,
        message: message,
        email: email,
        ...(queryFor && { emailFor: queryFor }),
      },
    });
    window.dataLayer.push({
      event: "lead_submitted",
    });
    console.log(window.dataLayer);
    return result;
  } catch (err) {
    throw err;
  }
}

async function sendEnquiry({ subject, message }) {
  try {
    const result = await axios({
      method: "POST",
      url: apiURL + "/api/email/snedEnquiryMail",
      data: {
        subject: subject,
        message: message,
      },
    });
    window.dataLayer.push({
      event: "lead_submitted",
    });
    console.log(window.dataLayer);
    return result;
  } catch (err) {
    throw err;
  }
}

function getImageBase64String(imageFile, callback) {
  const fileReader = new FileReader();
  fileReader.onload = () => {
    callback(fileReader.result);
  };
  return fileReader.readAsDataURL(imageFile);
}

async function saveNewDoc(params) {
  try {
    const response = await axios({
      method: "POST",
      url: apiURL + "/api/docs/",
      data: params,
      headers: {
        authorization: getTokenCookie(),
      },
      withCredentials: true,
    });
    return response.data.data.doc;
  } catch (err) {
    throw err;
  }
}

async function updateArticle(params) {
  try {
    const response = await axios({
      method: "PATCH",
      url: apiURL + "/api/docs",
      data: params,
      headers: {
        authorization: getTokenCookie(),
      },
      withCredentials: true,
    });
  } catch (err) {
    throw err;
  }
}

async function fetchAllArticlesForAuthor() {
  try {
    const response = await axios({
      method: "GET",
      url: apiURL + `/api/docs`,
      headers: {
        authorization: getTokenCookie(),
      },
      withCredentials: true,
    });
    return response.data.data.docs;
  } catch (err) {
    throw err;
  }
}

async function deleteArticle(authorId, articleId) {
  try {
    const response = await axios({
      method: "DELETE",
      url: apiURL + `/api/docs/${articleId}`,
      headers: {
        authorization: getTokenCookie(),
      },
      withCredentials: true,
    });
  } catch (err) {
    throw err;
  }
}

async function fetchArticle(authorId, articleId) {
  try {
    const response = await axios({
      method: "GET",
      url: apiURL + `/api/docs/${articleId}`,
      headers: {
        authorization: getTokenCookie(),
      },
      withCredentials: true,
    });
    return response.data.data.doc;
  } catch (err) {
    throw err;
  }
}

function generateLinksForHeadings(htmlString) {
  const stringToParse = `<div id="rootDiv">` + htmlString + `</div>`;
  const parser = new DOMParser();
  const rootDivDocument = parser
    .parseFromString(stringToParse, "text/html")
    .getElementById("rootDiv");
  const allHeadings = rootDivDocument.querySelectorAll([
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "h6",
  ]);
  for (const heading of allHeadings) {
    heading.id = heading.textContent;
  }
  rootDivDocument.querySelectorAll("*").forEach((val) => {
    val.style.color = "inherit";
  });
  return rootDivDocument;
}

function createLinkTagsForHeading(htmlString) {
  const stringToParse = `<div id="rootDiv">` + htmlString + `</div>`;
  const parser = new DOMParser();
  const rootDivDocument = parser
    .parseFromString(stringToParse, "text/html")
    .getElementById("rootDiv");
  const allHeadings = rootDivDocument.querySelectorAll([
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "h6",
  ]);

  const links = [];
  for (const heading of allHeadings) {
    links.push({
      text: heading.textContent,
      link: `#${heading.id}`,
      id: "link_" + heading.id,
    });
  }

  return links;
}

async function editDoc(authorId, params) {
  try {
    const response = await axios({
      method: "PATCH",
      url: apiURL + `/api/docs`,
      data: params,
      headers: {
        authorization: getTokenCookie(),
      },
      withCredentials: true,
    });
  } catch (err) {
    throw err;
  }
}

async function fetchArticleById(articleId) {
  try {
    const response = await axios({
      method: "GET",
      url: apiURL + `/api/docs/${articleId}`,
    });
    return response.data.data.doc;
  } catch (err) {
    throw err;
  }
}

async function fetchArticlesByModule(moduleName) {
  console.log("::::", moduleName);
  try {
    const response = await axios({
      method: "GET",
      url: apiURL + `/api/docs/module/${moduleName}`,
    });
    const data = response.data.data;
    const dataToReturn = {};
    dataToReturn[data.module] = [];
    for (const sectionName of Object.keys(data.section)) {
      const sectionLinks = data.section[sectionName];
      const tempObject = {};
      tempObject[sectionName] = sectionLinks;
      dataToReturn[data.module].push(tempObject);
    }
    return dataToReturn;
  } catch (err) {
    throw err;
  }
}

async function fetchArticleByPublishedId(publishId) {
  try {
    const response = await axios({
      method: "GET",
      url: apiURL + `/api/docs/published/${publishId}`,
    });
    return response.data.data.doc;
  } catch (err) {
    throw err;
  }
}

async function myProfile() {
  try {
    const response = await axios({
      method: "GET",
      url: apiURL + "/api/auth/me",
      headers: {
        authorization: getTokenCookie(),
      },
      withCredentials: true,
    });
    const { firstName, lastName, email } = response.data;
    console.log(firstName, lastName, email, "Utils");
    return { firstName, lastName, email };
  } catch (err) {
    throw err;
  }
}

async function searchDocsForKeyword(keyword) {
  try {
    const response = await axios({
      method: "GET",
      url: apiURL + `/api/docs/query`,
      params: new URLSearchParams({ keyword: keyword }),
      headers: {
        authorization: getTokenCookie(),
      },
      withCredentials: true,
    });
    return response.data.data.docs;
  } catch (err) {
    throw err;
  }
}

//Add URL validation for this
function getYTVideoIdFromURL(url) {
  try {
    const videoURL = new URL(url);
    const videoId = videoURL.searchParams.get("v");
    return videoId;
  } catch (err) {
    return "";
  }
}

async function getCategories() {
  try {
    const response = await axios({
      method: "GET",
      url: apiURL + "/api/blogs/categories",
    });
    return response.data.data.categories.map((val, idx) => {
      return val.name;
    });
  } catch (err) {
    throw err;
  }
}

async function fetchBlogsPublished() {
  try {
    const response = await axios({
      method: "GET",
      url: apiURL + "/api/blogs/published",
      headers: {
        authorization: getTokenCookie(),
      },
      withCredentials: true,
    });

    return response.data.data.blogs;
  } catch (err) {
    console.log(err);
    throw err;
  }
}

async function fetchAllBlogs() {
  try {
    const response = await axios({
      method: "GET",
      url: apiURL + "/api/blogs",
      withCredentials: true,
    });
    const blogs = response.data.data.blogs;
    return blogs;
  } catch (err) {
    throw err;
  }
}

async function fetchBlogByid(id) {
  try {
    const response = await axios({
      method: "GET",
      url: apiURL + `/api/blogs/${id}`,
    });

    const blog = response.data.data.blog;
    return blog;
  } catch (err) {
    throw err;
  }
}

async function saveNewBlog(params) {
  try {
    const response = await axios({
      method: "POST",
      url: apiURL + "/api/blogs",
      withCredentials: true,
      data: params,
    });
    return response.data.data.blog;
  } catch (err) {
    throw err;
  }
}

async function editBlog(params) {
  try {
    const response = await axios({
      method: "PATCH",
      url: apiURL + "/api/blogs",
      withCredentials: true,
      data: params,
    });
  } catch (err) {
    throw err;
  }
}

async function searchBlogsForKeyword(keyword) {
  try {
    const response = await axios({
      method: "GET",
      url: apiURL + `/api/blogs/query`,
      params: new URLSearchParams({ keyword: keyword }),
      withCredentials: true,
    });
    return response.data.data.blogs;
  } catch (err) {
    throw err;
  }
}

async function deleteBlog(id) {
  try {
    const response = await axios({
      method: "DELETE",
      url: apiURL + `/api/blogs/${id}`,
      withCredentials: true,
    });
  } catch (err) {
    throw err;
  }
}

async function fetchProductPrices() {
  try {
    const response = await axios({
      method: "GET",
      url: circiniusUrl + `/api/stripe/prices/v2`,
    });
    return response.data.data.prices;
  } catch (err) {
    throw err;
  }
}

async function setPinBlog (params) {
  try {
    const response = await axios({
      method: "POST",
      url: apiURL + `/api/blogs/pin`,
      data:params
    });

    return response.data;
  } catch (err) {
    throw err;
  }
}
async function getPinBlog () {
  try {
    const response = await axios({
      method: "GET",
      url: apiURL + `/api/blogs/pin`,
    });

    return response.data.data;
  } catch (err) {
    throw err;
  }
}
async function unPinBlog (params) {
  try {
    const response = await axios({
      method: "PATCH",
      url: apiURL + `/api/blogs/pin`,
      data:params
    });
    return response.data;
  } catch (err) {
    throw err;
  }
}

export {
  setPinBlog,
  unPinBlog,
  getPinBlog,
  createLinkTagsForHeading,
  redirectEvallo,
  redirectMail,
  redirectEvalloSignup,
  insertQueryInDynamoDb,
  insertNewsLetterEmailInDynamoDb,
  getImageBase64String,
  saveNewDoc,
  updateArticle,
  fetchAllArticlesForAuthor,
  generateLinksForHeadings,
  deleteArticle,
  fetchArticle,
  fetchArticleById,
  fetchArticlesByModule,
  editDoc,
  fetchArticleByPublishedId,  
  myProfile,
  searchDocsForKeyword,
  getYTVideoIdFromURL,
  sendEnquiry,
  getCategories,
  fetchAllBlogs,
  fetchBlogByid,
  saveNewBlog,
  editBlog,
  searchBlogsForKeyword,
  deleteBlog,
  fetchProductPrices,
  fetchBlogsPublished,
  insertQueryInDb,
};
